.screenMessage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 92vh;
    background-color: rgba(0, 0, 0, 0.0); /* Fundo escuro semi-transparente */
    display: flex;
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    align-items: center; /* Centraliza o conteúdo verticalmente */
    z-index: 1000;
  }
  
  .screenMessage-content {
    background-color: #fff;
    padding: 5vh;
    border-radius: 1.5vh;
    max-width: 20%;
    margin-top: -14vh;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .screenMessage-text{
    font-size: 2vh;
    
  }
  