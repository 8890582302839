.green-button {
    background-color: green;
    color: white;
    border: none;
    padding: 0.5vh 1vh;
    border-radius: 0.7vh;
    /* height: 3vh; */
    /* width: 7vh; */
    cursor: pointer;
    font-size: 1.7vh;
  }

  .green-button:hover {
    background-color: rgb(0, 92, 0);
  }

  .grey-button {
    background-color: grey;
    color: white;
    border: none;
    padding: 0.5vh 1vh;
    border-radius: 0.6vh;
    height: 3vh;
    /* width: 7vh; */
    cursor: pointer;
    font-size: 1.7vh;
  }

  .grey-button:hover {
    background-color: rgb(100, 100, 100);
  }

  .buttonMenu{
    background-color: transparent;
    border: 0;
}

.buttonMenu:hover{
    scale: 1.17; 
}

.buttonMenu:active{
    scale: 1;
}

.buttonMenu:disabled{
    scale: 1;
    opacity: 0.7;
}

.badge{
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
  height: 30%;
  font-size: 2vh;
  color: red;
}