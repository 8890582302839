.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
  }
  
  .modal {
    background: white;
    border-radius: 1vh;
    width: 35vh;
    padding: 2vh;
    box-shadow: 0 1vh 2vh rgba(0, 0, 0, 0.1);
    height: 14vh;
    margin-left: 40%;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .modal-header {
    margin-bottom: 1vh;
  }
  
  .modal-title {
    margin: 0;
    /* border: 1px solid red; */
    height: 9vh;
  }
  
  .modal-body {
    margin-bottom: 1vh;
    font-size: 1.8vh;
    /* border: 1px solid gray; */
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid green; */
  }
  
  .modal-close-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 1vh 2vh;
    border-radius: 0.6vh;
    height: 4vh;
    width: 8vh;
    cursor: pointer;
    font-size: 1.6vh;
  }

  .modal-cancel-button {
    background-color: gray;
    color: white;
    border: none;
    padding: 1vh 2.5vh;
    border-radius: 0.6vh;
    height: 4vh;
    width: 12vh;
    cursor: pointer;
    font-size: 1.6vh;
    margin-right: 2vh;
    margin-top: 2vh;
  }

  .modal-destroy-button {
    background-color: red;
    color: white;
    border: none;
    padding: 1vh 2.5vh;
    border-radius: 0.6vh;
    height: 4vh;
    width: 12vh;
    cursor: pointer;
    font-size: 1.6vh;
  }

  .modal-open-button {
    background-color: green;
    color: white;
    border: none;
    padding: 1vh 2.5vh;
    border-radius: 0.6vh;
    height: 4vh;
    width: 12vh;
    cursor: pointer;
    font-size: 1.6vh;
  }

  .modal-open-button2 {
    background-color: green;
    color: white;
    border: none;
    padding: 0.5vh 1vh;
    border-radius: 0.6vh;
    height: 3vh;
    width: 7vh;
    cursor: pointer;
    font-size: 1.5vh;
  }

  .modal-delete-button2 {
    background-color: red;
    color: white;
    border: none;
    padding: 0.5vh 1vh;
    border-radius: 0.6vh;
    height: 3vh;
    width: 7vh;
    cursor: pointer;
    font-size: 1.5vh;
  }

  .modal-destroy-button:hover {
    background-color: rgb(200, 0, 0);
  }

  .modal-open-button2:hover {
    background-color: rgb(0, 92, 0);
  }

  .modal-delete-button2:hover {
    background-color: rgb(200, 0, 0);
  }

  .modal-open-button:hover {
    background-color: rgb(0, 92, 0);
  }

  .modal-cancel-button:hover {
    background-color: rgb(106, 106, 106);
  }
  
  .modal-close-button:hover {
    background-color: #0056b3;
  }

  .modal-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
  }

  .modal2 {
    background: #f2f2f7;
    border-radius: 1vh;
    width: 20vh;
    padding: 2vh;
    box-shadow: 0 1vh 2vh rgba(0, 0, 0, 0.2);
    height: 6vh;
    /* margin-left: 30%;
    margin-top: 15%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  