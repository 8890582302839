.inputLineCont{
    width: 97%;
    margin-left: 2vh;
    border-bottom: 1px solid white;
}
.line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.desc {
    width: 55%;
    text-align: left;
    font-size: 1.9vh;
}
.ipt{
    width: 22%;
    text-align: left;
    font-size: 1.9vh;
    padding: 0.6vh;
    margin: 0.4vh;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    color:  var(--primary-color);
    
}
.iptCombo{
    width: 24%;
    text-align: left;
    font-size: 1.9vh;
    padding: 0.2vh;
    color:  #007bff;
    
}
/* .iptCont{
    width: 22%;
    border: 1px solid red;
    align-content: center;
    padding: 0.1vh;
} */
.optCont{
    width: 22%;
    padding: 0.2vh;
    text-align: left;
    font-size: 1.9vh;
    padding: 0.6vh;
    margin: 0.4vh;
}
.unit{
    width: 20%;
    text-align: left;
    font-size: 1.9vh;
    text-align: right;
    padding-right: 1vh;
    margin-right: 1vh;
}
select{
    width: 100%;
    text-align: left;
    font-size: 1.9vh;
    padding: 0.5vh;
    /* margin: 0.4vh; */
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    color:  #007bff;
}
.fullLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.6vh;
    

}
.fullLineRow{
    text-align: left;
    font-size: 1.9vh;
    
}
.fullLineRowBol{
    text-align: left;
    font-size: 1.9vh;
    
}
.outOfLimit{
    background-color: red;
    color: white;
}
