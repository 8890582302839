.menu_Logo{
    height: 8.1%;
    border-right: 1px solid #c2c2c7;
    width: 32vh;
    max-width: 25vw;
}

.menu_Version{
    font-size: 1.5vh;
    height: 1.7%;
    border-right: 1px solid #c2c2c7;
    width: 32vh;
    max-width: 25vw;
}
.logo_Costumer{
    height: 90%;
    padding: 0.8vh;
}
.menu_Head{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 2vh;
    font-weight: bold;
    text-align: left;
    min-height: 6vh;
    /* margin-left: 1vw;
    margin-right: 1vw; */
    padding-left: 1vh;
    padding-right: 1vh;
    padding-bottom: 0px;
    /* margin-bottom: -0.5vw; */
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: white;
    
    
}
.icon_Head{
    font-size: 2vh;
    color: var(--primary-color);
    /* border: 1px solid red; */
    min-width: 2vh;
    /* margin-top: 0.5vh; */
}
.unsel_Item{
    font-size: 2vh;
    color: black;
    text-align: left;
    background-color: white;
    margin-left: 1.8vh;
    margin-right: 0.3vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2vh;
    width: 26.2vh;
}
.sel_Item{
    font-size: 2vh;
    width: 26.2vh;
    color: white;
    text-align: left;
    background-color: var(--primary-color);
    margin-left: 1.8vh;
    margin-right: 0.3vh;
    padding-left: 2vh;
    padding-top: 1vh;
    padding-right: 1vh;
    padding-bottom: 1vh;
    border-radius: 0.6vh;
}
.p_Item{
    margin: 0.3vw;
    
}
.show_menuBme{
    display: none;
}

nav a {
    text-decoration: none;
    color: black;
}

nav a:hover{
    font-weight: bold;
}
