.psMainMenu{
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 7.5vh;
}

.psMenuButtom{
    position: relative;
    justify-content: center;
    align-items: center; 
    font-size: 1.5vh;
    
    color: var(--primary-color);
    height: 90%;
    min-width: 5vh;
    margin-right: 0.1vh;
    margin-left: 0.1vh;
    top: 8%;
}

.psButtom{
    background-color: transparent;
    border: 0;
    color: var(--primary-color);
}

.psButtom:hover{
    scale: 1.17; 
}

.psButtom:active{
    scale: 1;
}

.psButtom:disabled{
    scale: 1;
    opacity: 0.7;
}

.psMenuIcon{
     /* padding: 0.5vw; */
     margin-top: 0;
     margin-bottom: auto;
     font-size: 2.6vh;
     height: 4vh;
     width: 3vh;
     /* fill: var(--primary-color); */
     /* fill: var(--primary-color) !important; */
     /* border: 1px solid red; */
}

.psMenuText{
        /* position: absolute; */
        font-size: 1.4vh;
        font-weight: bold;
        min-width: 5vh;
        /* bottom: 0; */
}

.psTableHead{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   height: 24px;
    color: white;
    background-color: var(--primary-color);
    padding: 5px;
    font-weight: bold;
    align-items: center;
    
    font-size: 12px;
   
    z-index: 9999;
}

.psHeadElement{
   
    /* position: relative; */
    /* width: 5%; */
    text-align: center;
    
}

.psAlert{
    position: absolute;
    top: -12%; /* Alinha ao topo */
    right: 5%; /* Alinha à direita */
    background-color: red; /* Apenas um exemplo de cor de fundo */
    color: white; /* Apenas um exemplo de cor do texto */
    padding: 0.3vh;
    height: 1vh;
    width: 1vh; /* Apenas um exemplo de preenchimento */
    border-radius: 50%; /* Torna o alerta circular */
    font-size: 1.2vh; /* Ajuste o tamanho da fonte conforme necessário */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    
    
}

.psBody{
    /* flex: 14;
    background-color: #F2F2F7;
    min-width: 115vh; */
    overflow: auto;
}

.psMainBody{
    max-width: 100%;
    /* min-width: 88vw; */
    overflow: auto;
    /* border: 1px solid red; */
}

.psLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* color: white; */
    background-color: white;
    padding-left: 5px;
    height: 18px;
    /* font-weight: bold; */
    align-items: center;
    font-size: 11px;
    border-bottom: 1px solid #d2d2d7;
}

.psCompLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* color: white; */
    background-color: white;
    padding-left: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 40px;
    /* font-weight: bold; */
    align-items: center;
    font-size: 10px;
    border-bottom: 1px solid #d2d2d7;
}

.psLineImp{
    background-color: #f2f2f7;
}

.psSectionHead{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px; 
    background-color: #e2e2e7;
    padding: 3px;
    text-align: left;
    font-weight: bold;
    font-size: 11px;
    z-index: 1; 
    border-bottom: 1px solid #d2d2d7;
}



.parentContainer {
    overflow: visible; /* Certifique-se de que o overflow não está escondido */
}


.psCompSubLine{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    height: 100%;
}

