.react-datepicker-popper {
    z-index: 9999;  /* Garante que o seletor de datas fique à frente de todos os objetos */
    position: relative; 
  }
  
  .custom-datepicker-input {
    /* Outros estilos customizados para o botão */
    font-size: 2vh;
    padding: 0.5vh 1vh;
    border: 1px solid #ccc;
    border-radius: 0.8vh;
    background-color: #fff;
    cursor: pointer;
  }
  