.barra_Superior {
    height: 8%;
    max-height: 8vh;
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgb(200, 200, 200);
    overflow: hidden;
  }
.menu_BarraSup{
    display: flex;
    flex-direction: row;
    width: 30%;
    height: 100%;
    justify-content: flex-start;
    padding-left: 1vh;

  }
  .title_BarraSup{
    width: 40%;
    align-self: center;
    font-size: 2.4vh;
  
  }
  .logos_BarraSup{
    display: flex;
    flex-direction: row;
    width: 30%;
    height: 100%;
    justify-content: flex-end;
    padding-right: 1vw;
  }
  .logo_BarraSup{
    height: 100%;
  }
  .menu_Item{
    padding: 0.5vw;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 3vh;
  }
  .iconText{
    position: absolute;
    font-size: 1.5vh;
    bottom: 0;
  }
  .buttom{
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 1.5vh;
    color: rgb(0, 122, 254);
    height: 80%;
    align-self: center;
    width: 6vh;
    margin: 1.5vh;
  }
  .buttom.clicked{
    background-color: #e2e2e7;
  }
  .modal {
    position: relative;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
  }
  .badge{
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 30%;
    font-size: 2vh;
    color: red;
  }

