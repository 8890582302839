.custom-table{
    width: 90%;
    max-width: 150vh;
    margin: auto;
    margin-top: 2vh;
    font-size: 1.6vh;
    text-align: left;
    /* font-weight: normal; */
    border-collapse: collapse;
}
.first-column {
    /* width: 30%; */
    padding: 1vh;
}
.first-row{
    background-color: var(--primary-color);
    color: white;
    /* font-weight: bold; */
}
.second-row{
    background-color: white;
    color: black;
    /* font-weight: 100; */
}
.third-row{
    background-color: #f2f2f7;
}

.custom-table th:not(.first-column),
.custom-table td {
    /* width: calc((100% - 30%) / 4); */
    padding: 1vh;
}
