
.selected-item {
    text-align: center;
  }
  
  .controls-container {
    --highlight-width: auto;
    --highlight-x-pos: 0;
    padding: 0.25vh;
    display: flex;
    justify-content: space-between;
    margin-right: 1vh;
    margin-left: 1.6vh;
    font-size: 1.9vh;
    align-items: center;
    border-bottom: 1px solid #f2f2f7;
  }

  .screenControls-container {
    padding: 0.3vh;
    /* --highlight-width: auto;
    --highlight-x-pos: 0;
    padding: 0.25vh;
    display: flex;
    justify-content: space-between;
    margin-right: 1vh;
    margin-left: 1.6vh;
    font-size: 1.6vh;
    align-items: center;
    border-bottom: 1px solid #f2f2f7; */
  }
  
  .controls {
    display: inline-flex;
    justify-content: space-between;
    background: #e2e2e7;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    border-radius: 1vh;
    width: 28.6vh;
    /* padding: 0.25vh; */
    /* margin: auto; */
    overflow: hidden;
    position: relative;
    border: 1px solid #e2e2e7;
  }


  
  .controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .screenControls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }
  
  .segment {
    width: 100%;
    /* min-width: 120px; */
    position: relative;
    text-align: center;
    z-index: 1;
  }
  
  .segment label {
    cursor: pointer;
    display: block;
    /* font-weight: 700; */
    padding: 0.8vh;
    transition: color 0.5s ease;
    font-size: 1.6vh;
  }
  
  .segment.active label {
    font-size: 1.6vh;
  }

  .segmentScreen {
    width: 100%;
    /* min-width: 120px; */
    position: relative;
    text-align: center;
    z-index: 1;
  }

  .segmentScreen label {
    cursor: pointer;
    display: block;
    /* font-weight: 700; */
    padding: 0.8vh;
    transition: color 0.5s ease;
    font-size: 1.6vh;
  }

  .segmentScreen.active label {
    font-size: 1.6vh;
    color: #ffffff;
  }
  
  .controls::before {
    content: "";
    background: white;
    border-radius: 1vh;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  
  /* Only allow transitions once component is ready */
  .controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  .controls-containerScreen {
    --highlight-width: auto;
    --highlight-x-pos: 0;
    padding: 0.25vh;
    display: flex;
    justify-content: center;
    margin: 0.3vh;
    font-size: 1.6vh;
    align-items: center;
    border: 1px solid red;
  }

  .controlsScreen {
    display: inline-flex;
    justify-content: space-between;
    background: #e2e3e7;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    border-radius: 1vh;
    width: 90vh;
    /* padding: 0.25vh; */
    /* margin: auto; */
    overflow: hidden;
    position: relative;
    border: 1px solid #e2e2e7;
  }

  .controlsScreen input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .controlsScreen.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  .controlsScreen::before {
    content: "";
    background: var(--primary-color);
    border-radius: 1vh;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  